<template>
  <div class="Site PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('hall.default[3]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
    <van-swipe :autoplay="2000" :vertical="isVertical" v-if="robotList.length>0"
               indicator-color="#191C23"
               class="hallswipe">
      <van-swipe-item v-for="(item, index) in robotList" :key="index">
        <img class="robotheader" :src="`./static/head/${item.header}`" />
        <div style="margin-top: -28px;margin-left: 40px">
          {{item.username}}
          {{$t('common5[0]')}} {{item.amount}}USDT {{item.time}}{{$t('common5[1]')}}{{$t('common5[2]')}}
        </div>
      </van-swipe-item>
    </van-swipe>
    <div class="infoBox" style="line-height: 28px">
<!--      <div class="panelHeader">-->
<!--        {{$t('hall4[2]')}}-->
<!--      </div>-->
      <div style="font-size:18px;width: 50%">{{ UserInfo.username.substring(0,15) }}****</div>
      <div style="display: flex">
        <div style="width: 40%">{{$t('bindAccount.fields[0]')}} </div>
        <div>: {{CardInfo.name}}</div>
      </div>
      <div style="display: flex">
        <div style="width: 40%"> {{ $t("hall5[0]") }} (USDT)</div>
        <div>: {{ parseFloat(UserInfo.balance).toFixed(2)}}</div>
      </div>
      <div style="display: flex">
        <div style="width: 40%"> {{$t('buy.label[2]')}} </div>
        <div>: {{CardInfo.card_no}}</div>
      </div>
    </div>
    <div class="ScrollBox">
            <div class="infoBox">
              <van-cell-group>
                <van-field readonly
                        v-model="parseFloat(CoinInfo.price).toFixed(2)"
                        :label="$t('sell.label[3]')"
                        :placeholder="$t('bindAccount.placeholder[3]')"
                />
                <van-field readonly
                        v-model="parseFloat(CoinInfo.bestPrice).toFixed(2)"
                        @click="showUsdtType = true"
                        :label="$t('sell.label[4]')"
                        :placeholder="$t('bindAccount.placeholder[4]')"
                />
                <div style="display: flex;background-color: #191C23">
                <van-field readonly
                        v-model="BankInfo.price"
                        :label="$t('sell.label[5]')"
                        :placeholder="$t('sell.placeholder[0]')"
                />
                  <van-button color="#F0B80D" size="min" style="width: 140px;height: 35px;padding: 5px" @click="setPrice">
                    <span style="color:#FFF;font-size: 14px">{{$t('sell.label[7]')}}</span></van-button>
                </div>
                <div style="display: flex;background-color: #191C23">
                <van-field
                        v-model="BankInfo.total_number"
                        :label="$t('sell.label[6]')"
                        :placeholder="$t('sell.placeholder[1]')"
                />
                  <van-button color="#F0B80D" size="min" style="width: 100px;height: 30px" @click="setAmount">
                    <span style="color:#FFF;font-size: 14px">{{$t('sell.label[8]')}}</span></van-button>
                </div>
              </van-cell-group>
            </div>
      <div style="text-align: center;margin-top: 20px">
        <p class="btn" @click="onSubmit">{{ $t('sell.label[9]') }}</p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'BindAccount',
  components: {

  },
  props: [],
  data() {
    return {
      tabsActive: 0,
      weixinAcc: '',
      showUsdtType: false,
      isVertical:true,
      robotList:[],
      CardInfo:{},
      BankInfo:{price:null,total_number:null},
      CoinInfo:{price:0,bestPrice:0},
      columns : ['ERC20', 'TRC20', 'OMNI'],
      douyinImg: [],
      kuaishouImg: [],
      accountArr: [],
      bestPrice:0,
      priceRate:0,
      leveldata:[],
      tabsList: [],
    }
  },
  computed: {
  },
  watch: {

  },
  created() {
    var that = this;
    this.$toast.loading();
    this.$Model.GetUserInfo((data) => {
      var userinfo = data.info;
      var level = userinfo.vip_level;
      that.$Model.GetLevelData(data=>{
        for (var i=0;i<data.length;i++){
          var lldata = data[i];
          if (lldata['grade']==level){
            // that.bestPrice = parseFloat(lldata['usdt']).toFixed(2);
            that.priceRate = lldata['pump'];
            // that.bestPrice = parseFloat(usdtinfo['price']*(1+rate)).toFixed(2);
            // that.bestPrice = 0;
            break
          }
        }
        that.$Model.GetCommonData(data=>{
          let currs = data.currencylist;
          let usdtinfo = data.usdtinfo;
          that.CoinInfo['price'] = usdtinfo['price']
          currs.forEach(item=>{
            if (item['name']=="USDT"){
              // that.CoinInfo = item;
              var rr = 1+that.priceRate*100/100;
              that.CoinInfo['bestPrice'] = parseFloat(usdtinfo['price']*rr).toFixed(2);
              console.log("curr best price"+that.CoinInfo['bestPrice'])
            }
          })
          that.$Dialog.Close();
        })
      });
    });
    this.$Model.GetBankCardList(data=>{
      this.CardInfo = data.data[0];
    });

    this.$Model.GetRobotTasks({},(data) => {
      // debugger
      if (data['code']==1){
        this.robotList = data['info']
      }
    });

    this.$parent.navBarTitle = this.$t('bindAccount.default[0]')
    // this.tabsList = this.InitData.taskclasslist.filter(item=>item.bind_status==1&&item.state==1)
    // this.accountArr = this.tabsList.flatMap(item=>item.bind_type==2?[this.UserInfo[item.bind_field]?[{url:this.ApiUrl+this.UserInfo[item.bind_field]}]:[]]:(this.UserInfo[item.bind_field]||''))
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {

  },
  methods: {
    setPrice(){
      var price = this.CoinInfo.bestPrice
      this.BankInfo.price = parseFloat(price).toFixed(2);
    },
    setAmount(){
      this.BankInfo.total_number = this.UserInfo.balance;
    },
    onSubmit() {
      var tt_number = this.BankInfo.total_number*1;
      var balance =this.UserInfo.balance*1;
      if (this.BankInfo.price<=0){
        this.$Dialog.Toast(this.$t('mytask2[9]'));
        return;
      } else if (tt_number<=0){
        this.$Dialog.Toast(this.$t('mytask2[7]'));
        return;
      }else if (tt_number>balance) {
        this.$Dialog.Toast(this.$t('mytask2[8]'));
        return;
      }
        this.BankInfo.name = this.CardInfo['name'];
      this.BankInfo.card_no = this.CardInfo['card_no'];
      this.BankInfo.task_type = 2;
      this.BankInfo['task_id'] = 0;
      this.BankInfo['ordertype'] = 2;

      this.$Model.SubmitTaskOrder(
              this.BankInfo,
              (data) => {
                let msg = '';
                debugger
                switch (data.code){
                  case 1:
                    msg = this.$t('common3[0]');
                    break;
                  case -1:
                    msg = this.$t('mytask2[7]');
                    break;
                  case -2:
                    msg = this.$t('hall2[2]');
                    break;
                  case -3:
                    msg = this.$t('mytask2[8]');
                    break;
                  case -4:
                    msg = this.$t('mytask2[7]');
                    break;
                  case -5:
                    msg = this.$t('mytask2[10]');
                  case -6:
                    msg = this.$t('common5[5]');
                    break
                  case -7:
                    msg = this.$t('common4[7]');
                    break
                  case -8:
                    msg = this.$t('common4[8]');
                    break
                  case -9:
                    msg = this.$t('common4[9]');
                    break
                  default:
                    msg = data.code_dec;
                    break;
                }
                let duration = 2000;
                if (data.wait){
                  duration = data.wait*1000
                }
                let msgg = {
                  message: msg,
                  duration: duration
                }
                this.$toast(msgg);
                if (data.code==1){
                  this.$router.push("/taskDetail/"+data.id);
                }
              }
      );
    },
    onChangeType(obj,value){
      this.UserInfo.usdttype = value;
      this.showUsdtType = false;
    },
  }
}
</script>
<style scoped>
  .PageBox {
    color: #635327;
    background-color: #13171A;
  }

  .van-nav-bar {
    background-color: #191C23;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#cccccc;
    font-weight: bold;
  }


  .hallswipe {
    width: 96%;
    margin-left: 6px;
    padding: 15px;
    height: 60px;
    z-index: 50;
    margin-top: 5px;
    margin-bottom: 5px;
    color:#cccccc;
    background-color: #191C23;
  }

  .robotheader{
    font-size: 19px;
    border-radius: 50%;
    background: pink;
    overflow: hidden;
    width: 32px;
    height: 32px;
  }

  .infoBox {
    width: 95%;
    margin-left: 10px;
    background-color: #191C23;
    padding: 10px;
    color: #e7e7e7;
    margin-bottom: 10px;
  }

  .panelHeader {
    border: 1px solid #cccccc;
    border-radius: 5px;
    color: #cccccc;
    width: 40%;
    text-align: center;
    margin-bottom: 10px;
  }

  .van-cell {
    background: #191C23;
  }

.van-cell>>>.van-cell__title{
  font-size: 14px;
  color: #cccccc;
}
.van-uploader>>>.van-uploader__upload,.van-uploader>>>.van-uploader__preview-image,.van-uploader>>>.van-uploader__preview{
  margin: 0;
  background-color: #f7f8fa;
  border-radius: 8px;
}

  .btn {
    width: 85%;
    padding: 10px 50px;
    border-radius: 20px;
    background-color: #F0B80D;
    color: #000;
    font-size: 18px;
    text-align: center;
    margin: 15px 30px 30px;
  }
</style>
